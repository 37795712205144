<template>
   <!-- 收支账户 -->
   <div class="storage" v-loading.fullscreen.lock="fullscreen" element-loading-text="请求数据中">
     <div class="append">
       <div class="seach">
         <el-select style="width:224px" placeholder="请选择账户类型" v-model="orderPage.accountType" clearable filterable @change="find">
           <el-option v-for="item in accountTypeData" :key="item.id" :label="item.value" :value="item.id">{{item.value}}</el-option>
         </el-select>  
         <el-select style="width:224px" placeholder="请选择货币" v-model="orderPage.currency" clearable filterable @change="find">
           <el-option v-for="item in currencyData" :key="item.id" :label="item.value" :value="item.id">{{item.value}}</el-option>
         </el-select>  
         <el-select style="width:224px" placeholder="请选择账户状态" v-model="orderPage.accountStatus" clearable filterable @change="find">
           <el-option v-for="item in accountStatusData" :key="item.id" :label="item.value" :value="item.id">{{item.value}}</el-option>
         </el-select>
         <el-input style="width:200px" id="selectval" placeholder="请输入查询条件" v-model="orderPage.keyword" @keyup.enter.native = "find"></el-input>
         <el-button style="margin-left: 10px;" type="primary" icon="el-icon-search" @click="find">查询</el-button>  
       </div>
     </div>
    <div class="append_left">
          <el-button type="primary" icon="el-icon-circle-plus-outline" @click="addAccount">新增账户</el-button>
          <div>
            <el-tooltip content="生成报告" placement="top">
                <div class="setting el-icon-receiving" @click="report"></div>
            </el-tooltip>
            <el-tooltip content="下载报告" placement="top">
                <div class="setting el-icon-download" @click="exportTable"></div>
            </el-tooltip>
          </div>
          <!-- <el-button type="primary" icon="el-icon-circle-plus-outline" @click="addBtn">调整汇率</el-button> -->
      </div>
      <el-dialog
        title="收款单-下载报告"
        :visible.sync="downReportBox"
        width="width"
        :before-close="downClose">
        <el-table
            :data="downList"
            border
            cell-style="text-align:center"
            header-cell-style="text-align:center;background:#E4E7ED"
            style="width: 100%">
            <el-table-column
                prop="createTime"
                label="申请时间"
                width="width">
            </el-table-column>
            <el-table-column
                prop="createBy"
                label="操作者"
                width="width">
            </el-table-column>
            <el-table-column
                prop="status"
                label="状态"
                width="width">
            </el-table-column>
            <el-table-column
                label="操作"
                width="width">
                <template slot-scope="scope">
                    <el-button :disabled="scope.row.status=='队列中'||scope.row.status=='下载失败'?true:false" type="text" @click="down(scope.row.fileUrl)">下载</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="text-align:right;margin:20px">
            <!-- <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[100, 200, 300, 400]"
            :page-size="100"
            layout="total, sizes, prev, pager, next, jumper"
            :total="400">
            </el-pagination> -->
        </div>
        <div slot="footer">
            <el-button @click="downReportBox = false">取 消</el-button>
            <el-button type="primary" @click="downReportBox = false">确 定</el-button>
        </div>
      </el-dialog>

     <!-- 列表区域 -->
     <el-table 
       border 
       id="myTable" 
       row-key="id" 
       :data="OrderList" 
       v-loading="loading" 
       show-summary
       :summary-method="getSummaries"
       :header-cell-style="{background:'#EFEFEF'}" 
       @selection-change="handleSelectionChanges"
       style="margin-top: 10px"
     >
      <el-table-column label="#" type="index" align="center" width="60px"></el-table-column>
      <el-table-column label="账户名称" prop="accountName" align="center"></el-table-column>
      <el-table-column label="账户类型" prop="accountTypeName" align="center"></el-table-column>
      <el-table-column label="币种" prop="currencyName" align="center"></el-table-column>
      <el-table-column label="账号" prop="bankAccount" align="center"></el-table-column>
      <el-table-column label="账户金额(¥)" prop="balance" align="center"></el-table-column>
      <el-table-column label="应收金额(¥)" prop="receivableAmount" align="center"></el-table-column>
      <el-table-column label="应付金额(¥)" prop="payableAmount" align="center"></el-table-column>
      <el-table-column label="默认账号" prop="defaultAccount" align="center" width="330px">
          <template slot-scope="scope">
             <el-switch
               v-model="scope.row.defaultAccount"
               active-color="#13ce66"
               inactive-color="#ff4949"
               active-value = "1"
               inactive-value = "0"
               active-text="默认账号"
               inactive-text="非默认账号"
               @change="changeStatus(scope.row)"
               :disabled="scope.row.accountStatus == 1?true:false"
               >
             </el-switch>
          </template>
      </el-table-column>
      <el-table-column label="账户状态" prop="accountStatus" align="center">
        <template slot-scope="scope">
           <span style="color: green" v-if="scope.row.accountStatus == 0">启用</span>
           <span style="color: red" v-if="scope.row.accountStatus == 1">禁用</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-link icon="el-icon-edit" type="primary" style="margin-right: 15px" @click="asyncEdit(scope.row)">编辑</el-link>
          <el-link icon="el-icon-lock" type="danger" v-if="scope.row.accountStatus == 0" @click="Forbidden(scope.row)">禁 用</el-link>
          <el-link icon="el-icon-unlock" type="success" v-if="scope.row.accountStatus == 1" @click="Enable(scope.row)">启 用</el-link>
        </template>
      </el-table-column>
     </el-table>

     <!-- 分页区域 -->
     <el-pagination 
       @size-change="handleSizeChange" 
       @current-change="handleCurrentChange"
       layout="total, sizes, prev, pager, next, jumper" 
       :page-sizes="[20,50,100,200]" 
       :total="total"
       :page-size="orderPage.pageSize" 
       :current-page="orderPage.current" 
       background>
     </el-pagination>

     <!-- 新增账户 -->
     <el-dialog
       title="新增账户"
       :visible.sync="addDialogVisible"
       width="600px"
       center
       :close-on-click-modal="false"
      >
        <el-form :model="addForm" :rules="addRules" ref="addRuleForm" label-width="100px">
          <el-form-item label="账户名称：" prop="accountName">
            <el-input v-model="addForm.accountName" placeholder="请输入账户名称" style="width: 360px"></el-input>
          </el-form-item>
          <el-form-item label="账户类型：" prop="accountType">
            <el-select 
              clearable 
              v-model="addForm.accountType" 
              placeholder="请选择账户类型"
              style="width: 280px"
             >
              <el-option
                v-for="item in accountTypeList"
                :key="item.id"
                :label="item.value"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <el-button type="text" style="font-size:15px; margin-left: 12px" @click="manageType">类型管理</el-button>
          </el-form-item>
          <el-form-item label="开户机构：" >
            <el-input v-model="addForm.openAccount" placeholder="请输入开户机构" style="width: 360px"></el-input>
          </el-form-item>
          <el-form-item label="账号：" prop="account">
            <el-input v-model="addForm.account" placeholder="请输入账号" style="width: 360px"></el-input>
          </el-form-item>
          <el-form-item label="币种：" prop="currency">
            <el-select 
              clearable 
              v-model="addForm.currency" 
              placeholder="请选择币种"
              style="width: 360px"
             >
              <el-option
                v-for="item in currencyList"
                :key="item.id"
                :label="item.value"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="账户余额：" prop="accountBalance">
            <el-input type="number" v-model.number="addForm.accountBalance" placeholder="请输入账户余额" style="width: 360px"></el-input>
          </el-form-item>
          <el-form-item class="dialog-footer">
            <span>
              <el-button size="medium" @click="resetForm('addRuleForm')" style="margin-right: 15px">重置</el-button>
              <el-button size="medium" type="primary" @click="submitForm('addRuleForm')">确定</el-button>
            </span>
          </el-form-item>
         </el-form>
     </el-dialog>

     <!-- 编辑账户 -->
     <el-dialog
      center
      title="编辑账户"
      :visible.sync="editDialogVisible"
      width="600px"
      >
        <el-form :model="editForm" :rules="editRules" ref="editRuleForm" label-width="100px">
          <el-form-item label="账户名称：" prop="accountName">
            <el-input v-model="editForm.accountName" placeholder="请输入账户名称" clearable style="width: 360px"></el-input>
          </el-form-item>
          <el-form-item class="dialog-footer">
            <span>
              <el-button size="medium" @click="editDialogVisible = false" style="margin-right: 15px">取消</el-button>
              <el-button size="medium" type="primary" @click="confirmEdit('editRuleForm')">编辑</el-button>
            </span>
          </el-form-item>
        </el-form>
     </el-dialog>

     <!-- 类型管理 -->
     <el-dialog
       center
       title="类型管理"
       :visible.sync="managementVisible"
       width="800px"
       :close-on-click-modal="false"
      >
        <el-row style="margin-bottom: 10px">
          <span style="font-size: 16px; font-weight:400">所有类型</span>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6" v-for="(item, index) in dataArr" :key="index" class="el_Col">
            <el-input
              value-key
              @blur="changeValue(index)"
              :disabled="index<4"
              v-model="item.value"
            >
            </el-input>
            <i class="el-icon-close i_icon" @click="remove(item.id, index)"></i>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6" style="margin-bottom: 15px">
            <el-button icon="el-icon-plus" type="primary" @click="addType">添加类型</el-button>
          </el-col>
        </el-row>
      <el-row>
        <el-button size="medium" @click="managementVisible = fales" style="float: right">取 消</el-button>
      </el-row>
     </el-dialog>
   </div>
</template>

<script>
  import { 
    getaddInit,
    getAddData,
    getPageInit,
    getInitSearch,
    editData,
    updateDefaultAccount,
    updateAccountStatus,
    addOrUpdateCategory,
    getCategoryInit,
    deleteCategory,
    exportExcel,exportRecort
  } from '@/api/financialManage/account.js'

  export default {
    data() {
     return {
       downReportBox:false,
       fullscreen:false,
       loading: false,
       addDialogVisible: false, 
       editDialogVisible: false,
       managementVisible: false,
       accountTypeList: [],
       currencyList: [],
       accountTypeData: [],
       currencyData: [],
       accountStatusData: [],
       OrderList: [],
       dataArr: [],
       inputVisible: false,
       inputValue: '',
       inputData: '',
       inputId: '',
       abc: '',
       total: 0,
       currentId: '',
       orderPage: {
         accountType: "",  // 账户类型
         currency: "",   // 币种
         accountStatus: "",  // 账户状态
         keyword: "",
         current: 1,
         pageSize: 20
       },
       addForm: {
         accountName: "",
         accountType: "",
         openAccount: "", // 开户机构
         account: "",
         currency: "",
         accountBalance: ""
       },
       editForm: {
         accountName: "",
       },
       addRules: {
         accountName: [
           { required: true, message: '请输入账户名称', trigger: 'blur' },
         ],
         accountType: [
           { required: true, message: '请选择账户类型', trigger: 'change' }
         ],
         currency: [
           { required: true, message: '请选择币种', trigger: 'change' }
         ],
         accountBalance: [
           { required: true, message: '请输入账户余额', trigger: 'blur' },
         ]
       },
       editRules: {
         accountName: [
           { required: true, message: '请输入账户名称', trigger: 'blur' },
         ],
       }
     }
    },
    created() {
      this.Search();
      this.getInitData();
    },
    methods: {
      // 新增账户 
      addAccount() {
        this.addDialogVisible = true;
        this.addInitData();  // 新增初始化
      },
      addInitData() {
        getaddInit()
        .then(res=> {
          if(res.data.code == 200) {
            if(res.data.data.accountTypeList&&Array.isArray(res.data.data.accountTypeList)&&res.data.data.accountTypeList.length) {
              this.accountTypeList = res.data.data.accountTypeList;
            }else {
              this.accountTypeList = [];
            }
            if(res.data.data.currencyList&&Array.isArray(res.data.data.currencyList)&&res.data.data.accountTypeList.length) {
              this.currencyList = res.data.data.currencyList;
            }else {
              this.currencyList = [];
            }
          }
        })
      },
      // 重置  
      resetForm(formName) {
        this.addForm.openAccount = "";
        this.$refs[formName].resetFields();
      },
      // 确定 
      submitForm(formName) {
        this.$refs[formName].validate(valid => {
           if(!valid) {
             return false;
           }
           let data = {
             accountName: this.addForm.accountName,
             accountType: this.addForm.accountType,
             balance: this.addForm.accountBalance,
             bank: this.addForm.openAccount,
             bankAccount: this.addForm.account,
             currency: this.addForm.currency
           }
           getAddData(data)
            .then(res=> {
               if(res.data.code == 200) {
                 this.$message.success(res.data.message);
                 this.addDialogVisible = false;
                 this.Search();   // 刷新页面
                 this.resetForm(formName);  // 清空数据
               }else {
                 this.$message.error(res.data.message);
               }
            })
        });
      },
      // 初始化数据  
      getInitData() {
         getPageInit()
          .then(res=> {
            if(res.data.code == 200) {
              this.accountTypeData = [];
              if(res.data.data.accountTypeList&&Array.isArray(res.data.data.accountTypeList)&&res.data.data.accountTypeList.length) {
                this.accountTypeData = res.data.data.accountTypeList;
              }

              this.currencyData = [];
              if(res.data.data.currencyList&&Array.isArray(res.data.data.currencyList)&&res.data.data.currencyList.length) {
                this.currencyData = res.data.data.currencyList;
              }

              this.accountStatusData = [];
              if(res.data.data.accountStatusList&&Array.isArray(res.data.data.accountStatusList)&&res.data.data.accountStatusList.length) {
                  this.accountStatusData = res.data.data.accountStatusList;
              }
            }
          })  
      },
      // 查询
      Search() {
        let params = {
          current: this.orderPage.current,
          pageSize: this.orderPage.pageSize,
          keyword: this.orderPage.keyword,
          accountType: this.orderPage.accountType,
          currency: this.orderPage.currency,
          accountStatus: this.orderPage.accountStatus
        }
         getInitSearch(params)
         .then(res => {
           this.OrderList = [];
           if(res.status == 200) {
             this.OrderList = res.data.values;
             this.total = res.data.pageInfo.total;
           }
         })
      },
      asyncEdit(row) {
        this.currentId = row.id;
        this.editForm.accountName = row.accountName;
        this.editDialogVisible = true;
      },
      // 确认编辑
      confirmEdit(formName) {
         this.$refs[formName].validate(valid => {
           if(!valid) {
              return false;
           }
           let data = {
             accountName: this.editForm.accountName,
             id: this.currentId
           }
           editData(data)
            .then(res=> {
              if(res.data.code == 200) {
                this.$message.success(res.data.message);
                this.editDialogVisible = false;
                this.Search();  // 刷新页面
              }else{
                this.$message.error(res.data.message);
              }
            })
         })
      }, 
      changeStatus(row) {
        updateDefaultAccount(row.id)
        .then(res=> {
          if(res.data.code == 200) {
            console.log(res, '00000000000')
            this.$message.success(res.data.data.message);
            this.Search();
          }else if (res.data.code == 500) {
            this.$message.error(res.data.message)
          }
        })
      },
      // 禁用
      Forbidden(row) {
        this.$confirm( `你确定要${row.accountStatus == 1 ? "启用": "禁用"}吗?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(()=> {
          updateAccountStatus(row.id)
           .then(res=> {
             if(res.data.code == 200) {
               this.$message.success('禁用成功');
               this.Search();
             }else {
               this.$message.error('操作失败');
             }

           })
        })
      },
      // 启用
      Enable(row) {
        this.$confirm( `你确定要${row.accountStatus == 1 ? "启用": "禁用"}吗?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(()=> {
          updateAccountStatus(row.id)
          .then(res=> {
            if(res.data.code == 200) {
              this.$message.success('启用成功');
              this.Search();
            }else {
              this.$message.success('操作失败');
            }
          })
        })

      },
      // 类型管理
      manageType() {
        getCategoryInit()
        .then(res=> {
          if(res.data.code == 200) {
            this.dataArr = res.data.data.accountTypeList;
          }else {
            this.dataArr = [];
          }
        })
        this.managementVisible = true;
      },
      // 添加类型
      addType() {
        console.log(this.dataArr);
        if(!this.dataArr[this.dataArr.length-1].value){
          this.$message({
            type:'warning',
            message:'请填写添加内容'
          })
          return
        }
        this.dataArr.push({
          id: '',
          value: ''
        });
      },

      changeValue(index) {
        for(var i=0; i<this.dataArr.length; i++) {
          this.inputData = this.dataArr[index].value;
          this.inputId = this.dataArr[index].id;
        }
      
        let data = {
          id: this.inputId ? this.inputId: 0,
          value: this.inputData
        }
        if(!this.inputData){
          this.$message({
            type:'warning',
            message:'请输入内容'
          })
          return
        }
        addOrUpdateCategory(data)
         .then(res=> {
           if(res.data.code == 200) {
             this.$message.success(res.data.message);
             this.addInitData();
           }else {
             this.$message.error(res.data.message)
            //  this.manageType()
             
           }
         })
      },
      // 删除
      async remove(id, index) {
        if(id) {
          if(index< 4) {
            return false;
          }else {
            const confirmResult = await this.$confirm("确定删除该类型吗？", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }).catch((err) => err);
            if (confirmResult !== "confirm") {
              return;
            }
            deleteCategory(id)
            .then(res=> {
              if(res.data.code == 200) {
                this.$message.success(res.data.message);
                this.dataArr.splice(index, 1);
                this.addInitData();
              }else {
                this.$message.error(res.data.message);
              }
            })
          }
        }else {
          this.dataArr.splice(index, 1);
        }
      },
      handleSizeChange(newSize) {
        this.orderPage.pageSize = newSize;
        this.Search(); 
      },
      handleCurrentChange(newCurrent) {
        this.orderPage.current = newCurrent;
        this.Search();
      },
      getSummaries(param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = "合计";
            return;
          }
          const values = data.map((item) => Number(item[column.property]));
          if (
            column.property === "canSellCount" ||
            column.property === "canotSellCount" ||
            column.property === "inTransit" ||
            column.property === "noDelivered"
          ) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index];
          } else {
            sums[index] = "";
          }
        });

        return sums;
      },
      //导出
      exportTable(){
          this.downReportBox=true
          exportRecort().then(res=>{
              console.log(res.data.data.data);
              // if(res.data.code==200){
                  this.downList=res.data.data.data
              // }
          })
      },
      report(){
          this.$confirm('确认生成收款单报告？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
          }).then(() => {
              exportExcel({
                  accountType:this.orderPage.accountType,
                  currency:this.orderPage.currency,
                  keyword:this.orderPage.keyword,
                  accountStatus:this.orderPage.accountStatus
              })
              this.$alert('导出中，请稍等数分钟后下载，您可以在“下载报告中”查看情况并下载', '提示', {
                  confirmButtonText: '确定',
                  callback: action => {
                      // this.$message({
                      // type: 'info',
                      // message: `action: ${ action }`
                      // });
                      return ;
                  }
              });
          }).catch(() => {
              this.$message({
                  type: 'info',
                  message: '已取消生成'
              });          
          });
      },
      down(url){
          const link = document.createElement('a')
          document.body.appendChild(link)
          link.href =url
          link.click()
          document.body.removeChild(link)
      },
      find(){
        console.log(1);
        this.orderPage.current=1
        this.Search()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .storage {
    .append {
      height: 50px;
      display: flex;
      justify-content: flex-start;

      .seach {
        .el-input {
          margin-left: 4px;
        }

        .el-select {
          margin-right: 7px;
        }
      }
    }

    .el-pagination {
      padding-top: 50px;
      text-align: center;
      margin-bottom: 10px;
    }

    .input {
      margin-right: 30px;
    }
  }
.el-table-column {
  background-color: #e5e5e5ff;
}
.dialog-footer {
   margin-left: 100px;
   margin-top: 30px;
}
.button-new-tag {
    height: 36px;
    line-height: 32px;
    padding-top: 0;
    padding-bottom: 0;
}
.input-new-tag {
    width: 120px;
    margin-left: 0px;
    vertical-align: bottom;
}
.el_Col {
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-start;
  position: relative;
}
.i_icon {
  margin-top: 10px;
  margin-left: 148px;
  float: right;
  cursor: pointer;
  font-size: 15px;
  position: absolute;
}
.append_left{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.setting{
    color:#409EFF;
    font-size:25px;
    border:2px solid #409EFF;
    border-radius: 6px;
    overflow: hidden;
    width: 25px;
    height: 25px;
    margin-right:12px
}
</style>